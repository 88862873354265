<template>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <c-table
        ref="table"
        title="취급일지 결과 목록"
        :columns="resultGrid.columns"
        gridHeight="530px"
        :data="data.resultList"
        :merge="resultGrid.merge"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :editable="editable && !disabled"
        rowKey="chmDangerJournalResultId"
        @table-data-change="tableDataChange"
      >
        <template v-if="prevList && prevList.length>0"  slot="suffixTitle">
          <font class="text-negative" style="font-size:0.8em;font-weight:300;">
            {{ $language('※ 저장품목에 대한 전일 재고량입니다. 전일 재고량은 입고량 입력시 자동 계산됩니다.') }} 
          </font>
          <q-chip
            v-for="(prev, index) in prevList"
            :key="index"
            outline square 
            color="primary"
            text-color="white"
            class="q-ma-none customchipdept">
            <template>
              {{ '[' + prev.chmDangerResultDate +'] ' }}
              {{prev.chmDangerResultName}} : {{prev.chmDangerStock | toThousandFilter}}
            </template>
          </q-chip>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'chmDangerQuanSum'">
            <span v-html="getchmDangerQuanSum(props.row['chmDangerQuanSum'])" />
          </template>
        </template>
      </c-table>
    </div>
</template>

<script>
export default {
  name: 'danger-journal-result',
  props: {
    data: {
      type: Object,
      default: () => ({
        resultList: [],
      }),
    },
    prevList: {
      type: Array,
      default: () => []
    },
    resultGrid: {
      columns: [],
      merge: [],
      height: '520px'
    },
  },
  data() {
    return {
      editable: true,
      searchUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      
    };
  },
  computed: {
    disabled() {
      return this.data.chmDangerCompleteFlag === 'Y' || this.data.approvalStatusCd === 'ASC0000001'
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
    tableDataChange(props, col) {
      // chmDangerStock
      if (col.name === 'chmDangerIn' || col.name === 'chmDangerOut') {
        let filterData = this.$_.sortBy(this.$_.filter(this.data.resultList, { 
          chmDangerResultName: props.row.chmDangerResultName,
        }), 'chmDangerResultDate')
        let _idx = this.$_.findIndex(filterData, { chmDangerResultDate: props.row.chmDangerResultDate })
        let __idx = this.$_.findIndex(this.prevList, {chmDangerResultName: props.row.chmDangerResultName})
        if (_idx > 0) {
          if (__idx >= 0) {
            // 인덱스값이 있을 경우 전일 재고량 + 입고량 - 사용량 계산
            this.$set(props.row, 'chmDangerQuan', (Number(filterData[_idx - 1].chmDangerStock) + Number(props.row.chmDangerIn) - Number(props.row.chmDangerOut)) / props.row.calcuMethod.split("/")[1])
            this.$set(props.row, 'chmDangerStock', Number(filterData[_idx - 1].chmDangerStock) + Number(props.row.chmDangerIn) - Number(props.row.chmDangerOut))
            // 없을 경우 전일 재고량 없이 계산 
          } else {
            this.$set(props.row, 'chmDangerQuan', Number(props.row.chmDangerIn) - Number(props.row.chmDangerOut)) / props.row.calcuMethod.split("/")[1]
            this.$set(props.row, 'chmDangerStock', Number(props.row.chmDangerIn) - Number(props.row.chmDangerOut))
          }
        } else {
          // 전일 재고량 -> 날짜마다 구분이 여러개이기 때문에 필터링 시켜야함.
          if (__idx >= 0) {
            // 인덱스값이 있을 경우 전일 재고량 + 입고량 - 사용량 계산
            this.$set(props.row, 'chmDangerQuan', (Number(this.prevList[__idx].chmDangerStock) + Number(props.row.chmDangerIn) - Number(props.row.chmDangerOut)) / props.row.calcuMethod.split("/")[1])
            this.$set(props.row, 'chmDangerStock', Number(this.prevList[__idx].chmDangerStock) + Number(props.row.chmDangerIn) - Number(props.row.chmDangerOut))
            // 없을 경우 전일 재고량 없이 계산 
          } else {
            this.$set(props.row, 'chmDangerQuan', Number(props.row.chmDangerIn) - Number(props.row.chmDangerOut)) / props.row.calcuMethod.split("/")[1]
            this.$set(props.row, 'chmDangerStock', Number(props.row.chmDangerIn) - Number(props.row.chmDangerOut))
          }
        }
        if (props.row.editFlag !== 'C') {
          this.$set(props.row, 'editFlag', 'U')
          this.$set(props.row, 'chgUserId', this.$store.getters.user.userId)
        }
        // 날짜의 입고량을 입력시 그 날 이후 전부 계산시킴.
        if (_idx + 1 < filterData.length) {
          for (let i = _idx + 1; i < filterData.length; i++) {
              this.$set(filterData[i], 'chmDangerQuan', (Number(filterData[i - 1].chmDangerStock) + Number(filterData[i].chmDangerIn) - Number(filterData[i].chmDangerOut)) / filterData[i].calcuMethod.split("/")[1])
              this.$set(filterData[i], 'chmDangerStock', Number(filterData[i - 1].chmDangerStock) + Number(filterData[i].chmDangerIn) - Number(filterData[i].chmDangerOut))
            if (filterData[i].editFlag !== 'C') {
              this.$set(filterData[i], 'editFlag', 'U')
              this.$set(filterData[i], 'chgUserId', this.$store.getters.user.userId)
            }
          }
        }
      }
    },
    getchmDangerQuanSum(_val) {
      let addTxt = '';
      if (_val === null) {
        _val = '',
        addTxt = '';
      } else if (_val > 1) {
        addTxt = `<br><div class="quansumcls quansumOver blinking">${this.$language('초과')}</div>`;
      } else if (_val < 1) {
        addTxt = `<br><div class="quansumcls quansumDown">${this.$language('미만')}</div>`
      } else if (_val == 1) {
        addTxt = '<br><div class="quansumcls quansumEqual">FULL</div>';
      }
      return _val + addTxt;
    }
  }
};
</script>

<style lang="sass">
  .quansumcls
    width: 40px
    height: 20px
    border-radius: 5px
    color: #fff
    margin: 0 auto
    font-size: 12px
    font-weight: 600
  .quansumOver
    background-color: red
  .quansumDown
    background-color: gray
  .quansumEqual
    background-color: blue
</style>
