var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-lg-12 col-md-12 col-sm-12" },
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "취급일지 결과 목록",
            columns: _vm.resultGrid.columns,
            gridHeight: "530px",
            data: _vm.data.resultList,
            merge: _vm.resultGrid.merge,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            hideBottom: true,
            editable: _vm.editable && !_vm.disabled,
            rowKey: "chmDangerJournalResultId",
          },
          on: { "table-data-change": _vm.tableDataChange },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "chmDangerQuanSum"
                    ? [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.getchmDangerQuanSum(
                                props.row["chmDangerQuanSum"]
                              )
                            ),
                          },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _vm.prevList && _vm.prevList.length > 0
            ? _c(
                "template",
                { slot: "suffixTitle" },
                [
                  _c(
                    "font",
                    {
                      staticClass: "text-negative",
                      staticStyle: {
                        "font-size": "0.8em",
                        "font-weight": "300",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$language(
                              "※ 저장품목에 대한 전일 재고량입니다. 전일 재고량은 입고량 입력시 자동 계산됩니다."
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm._l(_vm.prevList, function (prev, index) {
                    return _c(
                      "q-chip",
                      {
                        key: index,
                        staticClass: "q-ma-none customchipdept",
                        attrs: {
                          outline: "",
                          square: "",
                          color: "primary",
                          "text-color": "white",
                        },
                      },
                      [
                        [
                          _vm._v(
                            " " +
                              _vm._s("[" + prev.chmDangerResultDate + "] ") +
                              " " +
                              _vm._s(prev.chmDangerResultName) +
                              " : " +
                              _vm._s(
                                _vm._f("toThousandFilter")(prev.chmDangerStock)
                              ) +
                              " "
                          ),
                        ],
                      ],
                      2
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }